<template>
  <div class="personal-content">
    <div class="header">
      <div class="info-message flex" @click="skip('/personal/info')">
        <img class="info-head"
          src="https://hmb-public-1302344794.cos.ap-nanjing.myqcloud.com/inclusive-health-insurance/img/relation-icon2.png">
        <div class="info-user">
          <div class="name">{{userInfo.realName}}</div>
          <div class="phone">{{userInfo.mobile}}</div>
        </div>
        <!-- <div class="info-user">
          <div class="login">点击登录</div>
        </div> -->
      </div>
      <div class="core-list flex">
        <div class="core-list-item" @click="skip('/order')">
          <img src="https://static1.webao99.com/bj24/frontinsured/img/personalCore_coreIcon1_ef5d7c9f.png">
          <p>我的订单</p>
        </div>
        <div class="core-list-item" @click="skip('/policy')">
          <img src="https://static1.webao99.com/bj24/frontinsured/img/personalCore_coreIcon2_29e34fa0.png">
          <p>我的保单</p>
        </div>
      </div>
    </div>
    <div class="serve">
      <div class="serve-title">工具与服务</div>
      <div class="serve-list flex">
        <div class="serve-list-item" @click="service">
          <img src="https://static1.webao99.com/bj24/frontinsured/img/personalCore_serveIcon1_f2d7702e.png">
          <p>客服</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: '',
  data () {
    return {
      userInfo: {
        realName: '--',
        mobile: '--'
      }
    }
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  created () {
  },
  mounted () {
    console.log(this.getUserInfo)
    this.userInfo = this.getUserInfo
		upsdk.pluginReady(function () {
		  upsdk.setTitleStyle({
		    navBackgroundColor: '0x8FFFFFFF',
		    appletStyle: 'black', //可选，black-黑色主题，white-白色主题
		    backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
		    appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
		  });
		});
  },
  methods: {
    skip (e) {
      this.$router.push({ path: e })
    },
    service () {
      window.open('https://yblkf.qiyukf.com/client?k=2bd82f3efd3e6e26a4e3e9226783093d&templateId=6663338&gid=484152786')
    }
  }
}
</script>
<style lang="less" scoped>
.personal-content {
  min-height: 100vh;
  background-color: #f7f7f7;
  font-family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif";
  .header {
    background-image: url("https://static1.webao99.com/bj24/frontinsured/img/personalCore_perbg_da3d48f1.png");
    background-repeat: no-repeat;
    background-size: 750px;
    width: 750px;
    height: 440px;
    position: relative;
    .info-message {
      position: absolute;
      top: 50px;
      left: 20px;
      right: 20px;
      .info-head {
        width: 142px;
        height: 142px;
        padding: 0 20px;
      }
      .info-user {
        margin-left: 20px;
        .name {
          color: #333;
          font-size: 36px;
          line-height: 40px;
          font-weight: 600;
        }
        .phone {
          color: #666;
          font-size: 30px;
          line-height: 40px;
          margin-top: 24px;
        }
        .login {
          font-size: 40px;
          font-weight: 600;
          color: #333;
        }
      }
    }
    .core-list {
      position: absolute;
      bottom: -65px;
      left: 20px;
      right: 20px;
      background-color: #fff;
      border-radius: 20px;
      .core-list-item {
        min-width: 25%;
        text-align: center;
        padding: 45px 0;
        font-size: 28px;
        img {
          width: 90px;
          height: 98px;
          margin-bottom: 20px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .serve {
    background-color: #fff;
    border-radius: 20px;
    margin: 0 20px;
    margin-top: 85px;
    .serve-title {
      padding: 20px;
      color: #333;
      font-size: 36px;
      font-weight: 600;
    }
    .serve-list {
      .serve-list-item {
        min-width: 25%;
        text-align: center;
        padding: 20px 0 45px 0;
        font-size: 28px;
        img {
          width: 90px;
          height: 90px;
          margin-bottom: 20px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
}
</style>
